$shooting-time: 6000ms;

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

.night {
  position: absolute;
  width: 40%;
  height: 120%;
  transform: rotateZ(45deg);
  z-index: 0;
  right: 15%;
  top: -30%;
}

.shootingStar {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, rgb(162, 191, 252), rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px rgb(164, 191, 246));
  animation:
    tail $shooting-time ease-in-out infinite,
    shooting $shooting-time ease-in-out infinite;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(
      -45deg,
      rgba(0, 0, 255, 0),
      rgb(162, 191, 252),
      rgba(0, 0, 255, 0)
    );
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining $shooting-time ease-in-out infinite;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(
      -45deg,
      rgba(0, 0, 255, 0),
      rgba(95, 145, 255, 1),
      rgba(0, 0, 255, 0)
    );
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining $shooting-time ease-in-out infinite;
    transform: translateX(50%) rotateZ(-45deg);
  }

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      $delay: randomNum(20, 9999) + 0ms;
      top: calc(50% - #{random(200) - 70px});
      left: calc(50% - #{randomNum(100, 250) + 0px});
      animation-delay: $delay;
      &::before,
      &::after {
        animation-delay: $delay;
      }
    }
  }
}

.fancyBannerCard {
  border-radius: 6px;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:hover {
    transform: translateY(-7px) rotate(-2deg);
  }
}

@keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes shining {
  0% {
    width: 0;
  }

  50% {
    width: 30px;
  }

  100% {
    width: 0;
  }
}

@keyframes shooting {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}
